<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="学校">
      <el-select v-model="selectModel.collegeId" placeholder="请选择" clearable>
        <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialogForm({created:true})">新建</el-button>
      </el-button-group>
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="学校" prop="collegeName" min-width="200px" />
    <el-table-column label="充值模版" prop="rechargeTemplateName" min-width="200px" />
    <el-table-column label="计划/实际上线时间" prop="planBegin" min-width="180px">
      <template #default="scope">
        {{ scope.row.status === 1 ? scope.row.planBegin : scope.row.begin }}
      </template>
    </el-table-column>
    <el-table-column label="计划/实际下线时间" prop="planBegin" min-width="180px">
      <template #default="scope">
        {{ scope.row.status === 3 ? scope.row.end : scope.row.planEnd }}
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="status" min-width="80px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('rechargeCollegeStatus', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="优先级" prop="priority" min-width="80px" />
    <el-table-column
      fixed="right"
      label="操作"
      width="60">
      <template #default="scope">
        <el-button v-if="scope.row.status === 1" @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button>
        <el-button v-if="scope.row.status === 2" @click="downAction(scope.row)" type="text" size="small">下线</el-button>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item label="学校" prop="collegeId" :rules="[
        { required: true, message: '学校不能为空', trigger: 'blur'}
      ]">
        <el-input v-if="created" v-model="dialog.form.collegeName" :readonly="true" />
        <el-select v-if="!created" v-model="dialog.form.collegeId" placeholder="请选择">
          <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
        </el-select>
      </el-form-item>
      <el-form-item label="充值模版" prop="rechargeTemplateId" :rules="[
        { required: true, message: '充值模版不能为空', trigger: 'blur'}
      ]">
        <el-select v-model="dialog.form.rechargeTemplateId">
          <el-option v-for="item in templates" :key="item.id" :value="item.id" :label="item.val" />
        </el-select>
      </el-form-item>
      <el-form-item label="上线时间" prop="planBegin" :rules="[
        { required: true, message: '上线时间不能为空', trigger: 'blur'}
      ]">
        <el-date-picker
          v-model="dialog.form.planBegin"
          type="datetime"
          placeholder="选择日期时间" />
      </el-form-item>
      <el-form-item label="下线时间" prop="planEnd" :rules="[
        { required: true, message: '下线时间不能为空', trigger: 'blur'}
      ]">
        <el-date-picker
          v-model="dialog.form.planEnd"
          type="datetime"
          placeholder="选择日期时间" />
      </el-form-item>
      <el-form-item label="优先级" prop="priority" :rules="[
        { required: true, message: '优先级不能为空', trigger: 'blur'}
      ]">
        <el-input-number :min="0" :max="99" v-model="dialog.form.priority" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { format } from '../../utils/datetime';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      colleges: [],
      templates: []
    };
  },
  created() {
    this.$api.getCollegeMap().then(data => {
      this.colleges = data;
    });
    this.$api.getRechargeMap().then(data => {
      this.templates = data;
    });
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getRechargeRelationPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
        this.$api.getRechargeParams(data.id).then(data => {
          data.forEach(element => {
            element.amount = Number(element.amount);
            element.give = Number(element.give);
          });
          this.dialog.form.params = data;
        });
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
        submit = () => {
          this.dialog.form.planEnd = format(this.dialog.form.planEnd);
          this.dialog.form.planBegin = format(this.dialog.form.planBegin);
          return this.$api.createRechargeRelation(this.dialog.form);
        };
      } else {
        this.dialog.title = '修改';
        submit = () => {
          this.dialog.form.planEnd = format(this.dialog.form.planEnd);
          this.dialog.form.planBegin = format(this.dialog.form.planBegin);
          return this.$api.updateRechargeRelation(this.dialog.form);
        };
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    },
    downAction(data) {
      this.$confirm('此操作将立即下线当前的充值活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.delRechargeRelation(data.id))
        .then(() => {
          this.$message.success('操作成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('操作取消'));
    }
  }
};
</script>
